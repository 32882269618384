import { TextLockupComponent as TextLockup } from '@vaa-component-lib/component.molecule.text-lockup';
import HTMLRenderer from 'src/components/html-renderer/html-renderer.component';
import { containsHTML } from 'src/utils/aem-utils/utils';
import styles from './text-lockup.component.module.less';
import classnames from 'classnames';

export default function TextLockupComponent(props: any) {
  const propsCopy = { ...props };

  Object.keys(propsCopy).forEach((key) => {
    if (typeof propsCopy[key] === 'string')
      propsCopy[key] = propsCopy[key]
        .replaceAll('&nbsp;', '')
        .replaceAll('\r', '')
        .replaceAll('\n', '')
        .trimRight();
  });

  // If children is a string containing HTML, render it
  if (props.hasOwnProperty('children') && containsHTML(props.children))
    propsCopy.children = HTMLRenderer({ htmlString: props.children });

  // Accordion content should not have padding
  if (props.hasOwnProperty('noPadding') && props.noPadding) {
    return <TextLockup {...propsCopy} />;
  }

  // Remove padding below text lockup, if specified
  const removeBottomPadding =
    props.hasOwnProperty('removeSpaceBelow') && props.removeSpaceBelow;

  return (
    <div
      className={classnames(styles['text-lockup'], {
        [styles['pb-none']]: removeBottomPadding,
      })}
    >
      <TextLockup {...propsCopy} />
    </div>
  );
}
