import styles from './route-map.component.module.less';
import classnames from 'classnames';
import HTMLRenderer from '../html-renderer/html-renderer.component';
import { Element } from '../html-renderer/html-renderer.component';
import { ButtonColour, ButtonComponent, ButtonPriority, ButtonSize, ButtonType, IconPosition } from '@vaa-component-lib/component.atom.button';
import { createRef, useState } from 'react';
import classNames from 'classnames';
import { IconActionCrossComponent, IconNavigationChevronArrowLeftComponent, IconNavigationArrowRightComponent } from '@vaa-component-lib/component.atom.icons';
import { TypographyComponent, TypographySize, TypographyVariant, TypographyWeight } from '@vaa-component-lib/component.atom.typography';

interface RouteMapProps {
  routeMapPath: string;
  title: string;
}

interface RouteMapComponentProps {
  ESIString: string;
  dataCy?: string;
  mobileTitle: string;
  buttonOnMobile?: boolean;
  buttonText?: string;
}

const RouteMapComponent = ({
  ESIString,
  dataCy = 'route-map',
  mobileTitle = 'Route Map',
  buttonOnMobile = true,
  buttonText = 'Open Route Map'
}: RouteMapComponentProps) => {

  const [isVisible, setIsVisible] = useState(false);
  const routeMapRef = createRef<HTMLDivElement>();

  if (!ESIString) {
    return null;
  }

  const onClose = () => {
    setIsVisible(false);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const setButtonsTabIndex = (index: number) => {
    const buttons = routeMapRef.current?.querySelectorAll('button');
    if (!buttons) {
      return;
    }
    buttons.forEach((button) => {
      button.tabIndex = index;
    });
  };

  const focusFirstButton = () => {
    const buttons = routeMapRef.current?.querySelectorAll('button');
    if (!buttons) {
      return;
    }
    buttons[0].focus();
  };

  const onAnimationEnd = () => {
    if (!isVisible) {
      setButtonsTabIndex(-1);
    }
    if (isVisible) {
      setButtonsTabIndex(0);
      focusFirstButton();
    }
  };

  return (
    <div id='route-map' data-cy={dataCy} className={classnames(styles['route-map'])}>
      <div className={classNames(
        styles['route-map__content'],
        {
          [styles['route-map__full-screen']]: buttonOnMobile,
          [styles['map-enter']]: isVisible && buttonOnMobile,
          [styles['map-exit']]: !isVisible && buttonOnMobile,
        }
      )}
        onAnimationEnd={onAnimationEnd}
        ref={routeMapRef}
      >
        {buttonOnMobile && (
          <div className={`${styles['route-map__header']} ${styles['mobile-show']}`}>
            <ButtonComponent
              size={ButtonSize.XSmall}
              aria-label="close"
              colour={ButtonColour.Inverse}
              iconChild={<IconNavigationChevronArrowLeftComponent />}
              onClick={onClose}
              type={ButtonType.Icon}
            />
            <TypographyComponent
              element="h3"
              variant={TypographyVariant.Heading}
              weight={TypographyWeight.Regular}
              size={TypographySize.Xsm}
            >
              {mobileTitle}
            </TypographyComponent>
            <ButtonComponent
              colour={ButtonColour.ContrastSecondary}
              size={ButtonSize.XSmall}
              iconChild={<IconActionCrossComponent />}
              type={ButtonType.Icon}
              onClick={onClose}
            />
          </div>
        )}
        <HTMLRenderer id='route-map-esi' htmlString={ESIString} element={Element.DIV} />
      </div>
      {buttonOnMobile && (
        <div className={styles['route-map__button']}>
          <ButtonComponent
            iconChild={<IconNavigationArrowRightComponent />}
            iconPosition={IconPosition.Right}
            text={buttonText}
            size={ButtonSize.Small}
            priority={ButtonPriority.Primary}
            fullWidth={true}
            onClick={() => toggleVisibility()}
          />
        </div>
      )}
    </div>
  );
};

export default RouteMapComponent;
