const commonGridTemplateColumns = '1fr 1fr 1fr 1fr';
const commonGridTemplateRows = '1fr';

export const layoutTemplates = {
  mobile: {
    mosaic: {
      gridTemplateColumns: commonGridTemplateRows,
      gridTemplateRows: commonGridTemplateRows,
      css: {
        even: ['small'],
        odd: ['small'],
      },
      grid: {
        even: [[1]],
        odd: [[1]],
      },
    },
    grid: {
      css: {
        odd: ['card'],
        even: ['card'],
      },
      gridTemplateColumns: commonGridTemplateRows,
      gridTemplateRows: commonGridTemplateRows,
      grid: { odd: [[1]] },
    },
  },
  tablet: {
    mosaic: {
      gridTemplateColumns: commonGridTemplateColumns,
      gridTemplateRows: commonGridTemplateRows,
      css: {
        even: ['large', 'middle', 'small', 'small'],
        odd: ['large', 'small', 'small', 'middle'],
      },
      grid: {
        even: [
          [1, 1, 1, 1],
          [1, 1, 1, 1],
          [2, 2, 3, 3],
          [2, 2, 4, 4],
        ],
        odd: [
          [1, 1, 1, 1],
          [1, 1, 1, 1],
          [2, 2, 4, 4],
          [3, 3, 4, 4],
        ],
      },
    },
    grid: {
      css: {
        odd: ['card', 'card', 'card', 'card'],
        even: ['card', 'card', 'card', 'card'],
      },
      gridTemplateColumns: commonGridTemplateColumns,
      gridTemplateRows: commonGridTemplateRows,
      grid: {
        odd: [
          [1, 1, 2, 2],
          [3, 3, 4, 4],
        ],
      },
    },
  },
  desktop: {
    mosaic: {
      gridTemplateColumns: commonGridTemplateColumns,
      gridTemplateRows: commonGridTemplateRows,
      css: {
        even: ['large', 'middle', 'small', 'small'],
        odd: ['small', 'small', 'middle', 'large'],
      },
      grid: {
        even: [
          [1, 1, 2, 3],
          [1, 1, 2, 4],
        ],
        odd: [
          [1, 3, 4, 4],
          [2, 3, 4, 4],
        ],
      },
    },
    grid: {
      css: {
        odd: ['card', 'card', 'card', 'card'],
        even: ['card', 'card', 'card', 'card'],
      },
      gridTemplateColumns: commonGridTemplateColumns,
      gridTemplateRows: commonGridTemplateRows,
      grid: { odd: [[1, 2, 3, 4]] },
    },
  },
};
