import Head from 'next/head';
import { PageHeader } from 'src/utils/pageheader';
import { assetPath } from 'src/utils/ssr-helper';

export default function PageHead({
  title,
  description,
  canonicalUrl,
  brandName,
  favicon,
  faviconAppleTouch,
  favicon16,
  favicon32,
  faviconSafariPinned,
  appleTouchIconPrecomposed,
  ensightenbootstrap,
  jQuery,
  preloadFiles,
}: PageHeader) {
  return (
    <>
      <Head>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta name="author" content={brandName} />
        <link rel="shortcut icon" href={favicon} />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href={appleTouchIconPrecomposed}
        />
        <link
          rel="apple-touch-icon-precomposed"
          href={appleTouchIconPrecomposed}
        />
        <link rel="apple-touch-icon" sizes="180x180" href={faviconAppleTouch} />
        <link rel="mask-icon" href={faviconSafariPinned} color="#da0530" />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        {ensightenbootstrap && <script src={ensightenbootstrap} async />}
        {jQuery && <script src={jQuery} async />}

        {/* Preload Next JS fonts */}
        <link
          rel="preload"
          href={assetPath + '/_nextfonts/gotham-book.woff2'}
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href={assetPath + '/_nextfonts/gotham-light.woff2'}
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href={assetPath + '/_nextfonts/gotham-medium.woff2'}
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        {/* Preload files for header-footer */}
        {preloadFiles &&
          preloadFiles.map((file) => (
            <link
              key={file.path}
              rel="preload"
              href={file.path}
              as={file.type}
            />)
          )}
      </Head>
    </>
  );
}
