import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { HotelMarker } from './hotel-marker.component';
import { MapMarker } from '../google-map/google-map.component';
import { HotelCardComponent } from '@vaa-component-lib/component.molecule.hotel-card';
import styles from './clustered-hotel-markers.component.module.less';
import { ButtonColour, ButtonComponent, ButtonSize, ButtonType } from '@vaa-component-lib/component.atom.button';
import { IconActionCrossComponent } from '@vaa-component-lib/component.atom.icons';

export interface HotelMapMarker extends MapMarker {
    name: string;
    image: {
        url: string;
        alt: string;
    };
    location?: string;
    link: {
        url: string;
        label?: string;
    }
    details?: string[] | React.ReactNode[];
    category: string;
    tripAdvisor?: {
        rating: number;
        reviews?: number;
    };
    virgin?: {
        rating: number;
    };
};

export type ClusteredHotelMarkersProps = {
    hotels: HotelMapMarker[];
};

/**
 * The ClusteredHotelMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredHotelMarkers = ({ hotels }: ClusteredHotelMarkersProps) => {

    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const [selectedHotelKey, setSelectedHotelKey] = useState<string | null>(null);

    const selectedHotel = useMemo(
        () =>
            hotels && selectedHotelKey
                ? hotels.find(t => t.key === selectedHotelKey)!
                : null,
        [hotels, selectedHotelKey]
    );

    const map = useMap();
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
        setMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key]))
                return markers;

            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const { [key]: _, ...newMarkers } = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setSelectedHotelKey(null);
    }, []);

    const handleMarkerClick = useCallback((hotel: HotelMapMarker) => {
        setSelectedHotelKey(hotel.key);
        (document.activeElement as HTMLElement)?.blur();

        // Focus the close button in the info window
        setTimeout(() => {
            const closeButton = document.querySelector('div[class*="hotel-info-header"] button') as HTMLButtonElement;
            closeButton?.focus({ preventScroll: true });
        }, 100);
    }, []);

    return (
        <>
            {hotels.map(hotel => (
                <HotelMarker
                    key={hotel.key}
                    hotel={hotel}
                    onClick={handleMarkerClick}
                    setMarkerRef={setMarkerRef}
                />
            ))}

            {selectedHotelKey && (
                <InfoWindow
                    headerContent={
                        <div className={styles['hotel-info-header']}>
                            <ButtonComponent
                                iconChild={<IconActionCrossComponent />}
                                colour={ButtonColour.InverseSecondary}
                                size={ButtonSize.XSmall}
                                type={ButtonType.Button}
                                onClick={handleInfoWindowClose}
                            />
                        </div>
                        }
                    className={styles['hotel-info-window']}
                    anchor={markers[selectedHotelKey]}
                    onCloseClick={handleInfoWindowClose}
                >
                    {selectedHotel?.name && (
                        <HotelCardComponent
                            className='hotels-map-card'
                            name={selectedHotel?.name}
                            location={selectedHotel?.location}
                            link={{ url: selectedHotel.link.url, label: selectedHotel.link.label ? selectedHotel.link.label : 'View Details' }}
                            image={{ url: selectedHotel?.image.url, alt: selectedHotel?.image.alt }}
                            tripAdvisor={selectedHotel?.tripAdvisor}
                            virgin={selectedHotel?.virgin}
                        />)}
                </InfoWindow>
            )}
        </>
    );
};
