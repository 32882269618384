import styles from './spacer.module.less';
import classNames from 'classnames';
// import styles from '../components/spacer-component/spacer-component.component.module.less';

interface SpacerProps {
  size: 'small' | 'large'
}

const SpacerComponent = ({ size = 'large' }: SpacerProps) => (
    <div
      // className={`${spacerStyles['spacer-main']} ${spacerStyles['spacer-main--debug']}`}
      className={classNames(styles['spacer-main'], {
          [styles['spacer-main--small']]: size === 'small'
      })}
    ></div>
  );

export default SpacerComponent;
