import {
  IconPanelComponent as IconPanel,
  IconPanelProps,
  IconPanelSizes,
} from '@vaa-component-lib/component.molecule.icon-panel';

import { IconActionCrossComponent } from '@vaa-component-lib/component.atom.icons';
import SVGRenderer from '../../components/svg-renderer/svg-renderer.component';
import classNames from 'classnames';
import getIcon from '../icons';
import style from './icon-panel.component.module.less';

export default function IconPanelComponent(props: any) {
  // Please change the not found icon as per the design requirement
  const IconNotFound = IconActionCrossComponent;

  const updatedProps: IconPanelProps = {
    ...props,
    items: props.items.map((item: any) => {
      if (item.hasOwnProperty('icon')) {
        const iconValue = item.icon;
        const Icon = iconValue
          ? getIcon(item.icon) ?? IconNotFound
          : IconNotFound;
        return {
          ...item,
          iconChild: <Icon />,
        };
      } else if (item.hasOwnProperty('iconChild')) {
        const iconChildValue = item.iconChild;
        const iconChild = iconChildValue ? (
          <SVGRenderer svgString={item.iconChild} />
        ) : (
          <IconNotFound />
        );

        return {
          ...item,
          iconChild,
        };
      } else {
        return { ...item, iconChild: <IconNotFound /> };
      }
    }),
  };

  return (
    <div
      className={classNames(style['icon-panel'], {
        [style['icon-panel--small']]: props.panelSize === IconPanelSizes.Small,
        [style['icon-panel--medium']]:
          props.panelSize === IconPanelSizes.Medium,
        [style['icon-panel--large']]: props.panelSize === IconPanelSizes.Large,
      })}
    >
      <IconPanel {...updatedProps} />
    </div>
  );
}
