import { useEffect, useState } from 'react';
import CountdownBannerComponent from './countdown-banner.component';

const CountdownTimerComponent = (props: any) => {
  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  useEffect(() => {
    const checkCountdown = setInterval(() => {
      if (props.endTime > Date.now() && props.startTime < Date.now()) {
        setBannerVisible(true);
      } else {
        setBannerVisible(false);
        clearInterval(checkCountdown);
      }
    }, 1000);

    return () => clearInterval(checkCountdown);
  }, [props.endTime, props.startTime]);

  return bannerVisible ? <CountdownBannerComponent {...props} /> : null;
};

export default CountdownTimerComponent;
