import React, { Ref, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import useCommonProps, { BaseComponent } from '../../hooks/useCommonProps';
import {
  ButtonComponent,
  ButtonProps,
  ButtonSize,
  ButtonPriority,
} from '@vaa-component-lib/component.atom.button';

import { SvgComponent, SvgType } from '../svg/svg.component';
import {
  TypographyComponent,
  TypographyVariant,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';

import style from './tailfin-hero-banner-two.component.module.less';
import { ScreenSize, useMediaBreakpoint } from 'src/hooks/use-media-breakpoint';
import useWindowSize from 'src/hooks/use-window-size';

export enum ButtonSkin {
  Default = 'default',
  Neutral = 'neutral',
  NeutralLight = 'neutral-light',
  Inverted = 'inverted',
}

export enum TailfinHeroBannerTwoPropsThemes {
  Light = 'light',
  Dark = 'dark',
}

export enum FocalPoint {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface TailfinHeroBannerTwoProps extends BaseComponent {
  heading: string;
  description: string;
  mobileImage: {
    url: string;
    alt: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  desktopImage: {
    url: string;
    alt: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  focalPoint: FocalPoint;
  buttons: ButtonProps[];
  theme?: TailfinHeroBannerTwoPropsThemes;
  motion?: boolean;
}

export const TailfinHeroBannerTwoComponent = ({
  heading,
  description,
  desktopImage,
  mobileImage,
  buttons,
  theme = TailfinHeroBannerTwoPropsThemes.Light,
  motion = true,
  focalPoint,
  testId = 'tailfin-hero-banner-two-component',
  testAttribute,
  testAttributeValue,
}: TailfinHeroBannerTwoProps) => {
  const desktopImageWidth = desktopImage?.dimensions?.width || 2316;
  const desktopImageHeight = desktopImage?.dimensions?.height || 850;

  const mobileImageWidth = mobileImage?.dimensions?.width || 1280;
  const mobileImageHeight = mobileImage?.dimensions?.height || 1900;

  const containerEl: Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [animated, setAnimated] = useState<boolean>(false);
  const [timing, setTiming] = useState<boolean>(true);
  const [scrollTriggerPosition, setScrollTriggerPosition] = useState<number>(0);
  const [motionPreference, setMotionPreference] = useState<boolean>(false);
  const currentBreakpoint = useMediaBreakpoint();
  const [width, height] = useWindowSize();
  const [fixedHeight, setFixedHeight] = useState<number>(0);
  const defaultHeight = 600;
  const [bannerHeight, setBannerHeight] = useState<number>(defaultHeight);

  const bannerImg =
    currentBreakpoint === ScreenSize.Mobile
      ? mobileImage.url
      : desktopImage.url;
  const altText =
    currentBreakpoint === ScreenSize.Mobile
      ? mobileImage.alt
      : desktopImage.alt;
  const imageHeight =
    currentBreakpoint === ScreenSize.Mobile
      ? mobileImageHeight
      : desktopImageHeight;
  const imageWidth =
    currentBreakpoint === ScreenSize.Mobile
      ? mobileImageWidth
      : desktopImageWidth;
  const imageFocalPoint =
    currentBreakpoint === ScreenSize.Mobile ? FocalPoint.Center : focalPoint;
  const scale = currentBreakpoint === ScreenSize.Mobile ? 0.85 : 0.95;
  const containerWidth = containerEl.current?.clientWidth;

  const commonProps = useCommonProps({
    testId,
    testAttribute,
    testAttributeValue: testAttributeValue || testId,
  });

  useEffect(() => {
    const calculatedFixedHeight = containerWidth
      ? (containerWidth / imageWidth) * imageHeight
      : 0;
    if (fixedHeight === 0 && calculatedFixedHeight !== 0) {
      setFixedHeight(calculatedFixedHeight);
    }
  }, [containerWidth, fixedHeight, imageHeight, imageWidth]);

  useEffect(() => {
    const calculatedHeight = containerWidth
      ? (containerWidth / imageWidth) * imageHeight
      : 0;
    const calculatedBannerHeight =
      currentBreakpoint === ScreenSize.Mobile ? calculatedHeight : fixedHeight;
    setBannerHeight(calculatedBannerHeight);
  }, [
    width,
    height,
    currentBreakpoint,
    containerWidth,
    fixedHeight,
    imageWidth,
    imageHeight,
  ]);

  useEffect(() => {
    setMotionPreference(
      !window.matchMedia('(prefers-reduced-motion: reduce)').matches
    );
  }, []);

  const config = useMemo(
    () => ({
      animateTimeout: 5000,
      scrollThreshold: 10,
      shouldAnimate: !motionPreference ? false : motion,
    }),
    [motion, motionPreference]
  );
  const isDarkTheme = theme === TailfinHeroBannerTwoPropsThemes.Dark;

  useEffect(() => {
    const el = containerEl.current as HTMLDivElement;
    let timerFunc: string | number | NodeJS.Timeout | undefined;

    const scrollingFunc = () => {
      if (getScrollPosition() >= scrollTriggerPosition) {
        setToAnimated();
      }
    };

    const intersectionListener = (entries: any) => {
      const [entry] = entries;
      const { intersectionRatio: fullRatio } = entry;
      const ratio = Number(fullRatio.toFixed(2));

      if (ratio >= 0.8) {
        timerFunc = setTimeout(() => {
          setToAnimated();
        }, config.animateTimeout);
      }

      if (ratio >= 0.9) {
        setScrollTriggerPosition(getScrollPosition() + config.scrollThreshold);
        observer.unobserve(el);
        window.addEventListener('scroll', scrollingFunc);
      }
    };

    const setToAnimated = () => {
      setAnimated(true);
      setTiming(false);
      clearTimeout(timerFunc);
      window.removeEventListener('scroll', scrollingFunc);
    };

    if (!window || !window.IntersectionObserver || !motion) {
      setToAnimated();
      return;
    }

    const getScrollPosition = () =>
      document.documentElement.scrollTop || document.body.scrollTop;

    const observer = new IntersectionObserver(intersectionListener, {
      root: null,
      rootMargin: '0px',
      threshold: [0.8, 0.9],
    });

    observer.observe(el);

    return () => {
      observer.unobserve(el);
      window.removeEventListener('scroll', scrollingFunc);
    };
  }, [config, motion, timing, containerEl, scrollTriggerPosition]);

  return (
    <div
      className={style.root}
      ref={containerEl}
      {...commonProps}
      style={{
        height: 'auto',
      }}
    >
      <div className={style['background--outer']}>
        {bannerImg && bannerHeight && (
          <>
            <div
              className={classnames(style.image, {
                [style['image--animated']]: animated,
                [style['image--static']]: !config.shouldAnimate,
                [style[`focus--${imageFocalPoint}`]]: true,
              })}
              style={{
                backgroundImage: `url('${bannerImg}')`,
                height: `${bannerHeight * scale}px`,
              }}
            >
              <span role="img" aria-label={altText} className="image-alt" />
            </div>
            <div
              className={classnames(style['tailfin-wrap'], {
                [style['tailfin-wrap--animated']]: animated,
                [style['tailfin-wrap--static']]: !config.shouldAnimate,
              })}
            >
              <SvgComponent
                type={SvgType.ExtendedTailFin}
                className={classnames(style.tailfin, style['tailfin--front'])}
                preserveAspectRatio="xMinYMin meet"
              />
            </div>
            <div
              className={classnames(style['tailfin-wrap'], {
                [style['tailfin-wrap--animated']]: animated,
                [style['tailfin-wrap--static']]: !config.shouldAnimate,
              })}
            >
              <SvgComponent
                type={SvgType.ExtendedTailFinTall}
                className={classnames(style.tailfin, style['tailfin--rear'])}
                preserveAspectRatio="xMinYMin meet"
              />
            </div>
          </>
        )}
      </div>
      <div className={style['content--outer']}>
        <article
          className={classnames(style.content, {
            [style['content--dark']]: isDarkTheme,
            [style['content--animated']]: animated,
            [style['content--static']]: !config.shouldAnimate,
          })}
        >
          <div className={style['content-inner']}>
            <div className={style.title}>
              <TypographyComponent
                element="h1"
                variant={TypographyVariant.Heading}
                size={TypographySize.Lrg}
                weight={TypographyWeight.Regular}
              >
                {heading}
              </TypographyComponent>
            </div>
            <div className={style.subtitle}>
              <TypographyComponent
                element="h2"
                variant={TypographyVariant.Body}
                size={TypographySize.Mdm}
                weight={TypographyWeight.Regular}
              >
                {description}
              </TypographyComponent>
            </div>
            <div className={style.buttons}>
              {buttons.map((props: any, i: number) => {
                let propsUpdated = {
                  ...props,
                };
                if (propsUpdated.hasOwnProperty('showIcon')) {
                  delete propsUpdated.showIcon;
                }

                const priority =
                  i === 0 ? ButtonPriority.Primary : ButtonPriority.Secondary;
                let skin =
                  i === 0 || animated
                    ? ButtonSkin.Default
                    : ButtonSkin.Inverted;

                if (isDarkTheme && i !== 0 && !animated) {
                  skin = ButtonSkin.Neutral;
                }

                return (
                  <div className={style.button} key={i}>
                    <ButtonComponent
                      size={ButtonSize.Medium}
                      {...{ priority, skin, ...propsUpdated }}
                      fullWidth={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};
