import PageRenderer from '../page-renderer/page-renderer.component';
import HomeLayout from '../layout-components/home-layout.component';
import LogoBar from 'src/components/logo-bar/logo-bar.component';
import { BRANDS, currentBrand } from 'src/utils/ssr-helper';
import errorTemplate from 'src/utils/aem-utils/cached-data/error-template.json';
import { useEffect, useState } from 'react';
import styles from './custom-error.component.module.less';
import classNames from 'classnames';
import { errorContent } from './custom-error-content';

interface ErrorComponentProps {
  errorCode?: number;
  errorMessage?: string | null;
}

type ErrorIconPanelItem = {
  icon: string;
  title: string;
  text: string;
  link: {
    href: string;
    text: string;
  };
};

type ErrorInfo = {
  headerText: string;
  headerWidth: string;
  messageText: string;
  messageWidth: string;
  iconPanelItems: ErrorIconPanelItem[];
};

const defaultErrorInfo: ErrorInfo = {
  headerText: '',
  headerWidth: '',
  messageText: '',
  messageWidth: '',
  iconPanelItems: [],
};

const CustomErrorComponent = ({
  errorCode = 404,
  errorMessage,
}: ErrorComponentProps) => {
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>(defaultErrorInfo);

  const brand: BRANDS = currentBrand ?? BRANDS.VHOLS;

  const {
    VHOLS_DEFAULT_HEADER,
    VAA_DEFAULT_HEADER,
    VHOLS_DEFAULT_MESSAGE,
    VAA_DEFAULT_MESSAGE,
    DEFAULT_400_MESSAGE,
    VHOLS_ICON_PANEL_ITEMS,
    VAA_ICON_PANEL_ITEMS,
  } = errorContent;

  const headingWidth = brand === BRANDS.VAA ? '700' : '600';
  const messageWidth = brand === BRANDS.VAA ? '550' : '846';

  useEffect(() => {
    const errorCodeGroup = Math.floor(errorCode / 100);
    console.log('errorCodeGroup', errorCodeGroup);

    switch (errorCodeGroup) {
      case 4:
        switch (brand) {
          case BRANDS.VAA:
            setErrorInfo({
              headerText: VAA_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VAA_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VAA_ICON_PANEL_ITEMS,
            });
            break;
          case BRANDS.VHOLS:
            setErrorInfo({
              headerText: VHOLS_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VHOLS_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VHOLS_ICON_PANEL_ITEMS,
            });
            break;
          default:
            setErrorInfo({
              headerText: VHOLS_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VHOLS_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VHOLS_ICON_PANEL_ITEMS,
            });
            break;
        }
        break;
      case 5:
        switch (brand) {
          case BRANDS.VAA:
            setErrorInfo({
              headerText: VAA_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VAA_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VAA_ICON_PANEL_ITEMS,
            });
            break;
          case BRANDS.VHOLS:
            setErrorInfo({
              headerText: VHOLS_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VHOLS_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VHOLS_ICON_PANEL_ITEMS,
            });
            break;
          default:
            setErrorInfo({
              headerText: VHOLS_DEFAULT_HEADER,
              headerWidth: headingWidth,
              messageText: errorMessage ?? VHOLS_DEFAULT_MESSAGE,
              messageWidth: messageWidth,
              iconPanelItems: VHOLS_ICON_PANEL_ITEMS,
            });
            break;
        }
        break;
      default:
        setErrorInfo(defaultErrorInfo);
        break;
    }
  }, [
    errorCode,
    errorMessage,
    brand,
    headingWidth,
    messageWidth,
    DEFAULT_400_MESSAGE,
    VHOLS_DEFAULT_MESSAGE,
    VHOLS_DEFAULT_HEADER,
    VAA_DEFAULT_MESSAGE,
    VAA_DEFAULT_HEADER,
    VHOLS_ICON_PANEL_ITEMS,
    VAA_ICON_PANEL_ITEMS,
  ]);

  const processErrorTemplate = () => {
    let result = JSON.stringify(errorTemplate)
      .replace('@@ERROR_HEADER@@', errorInfo.headerText)
      .replace('@@ERROR_MESSAGE@@', errorInfo.messageText)
      .replace('@@ERROR_HEADING_WIDTH@@', errorInfo.headerWidth)
      .replace('@@ERROR_MESSAGE_WIDTH@@', errorInfo.messageWidth);

    for (let i = 0; i < errorInfo.iconPanelItems.length; i++) {
      result = result
        .replace(
          `@@ICON_PANEL_ITEM_${i + 1}_ICON@@`,
          errorInfo.iconPanelItems[i]?.icon ?? ''
        )
        .replace(
          `@@ICON_PANEL_ITEM_${i + 1}_TITLE@@`,
          errorInfo.iconPanelItems[i]?.title ?? ''
        )
        .replace(
          `@@ICON_PANEL_ITEM_${i + 1}_TEXT@@`,
          errorInfo.iconPanelItems[i]?.text ?? ''
        )
        .replace(
          `@@ICON_PANEL_ITEM_${i + 1}_LINK_HREF@@`,
          errorInfo.iconPanelItems[i]?.link.href ?? ''
        )
        .replace(
          `@@ICON_PANEL_ITEM_${i + 1}_LINK_TEXT@@`,
          errorInfo.iconPanelItems[i]?.link.text ?? ''
        );
    }

    if (result.includes('@@')) {
      return null;
    }

    return JSON.parse(result);
  };

  return (
    <>
      <HomeLayout>
        <div className={classNames(styles.customError)}>
          <LogoBar brand={brand} />
          <PageRenderer items={processErrorTemplate()} />
        </div>
      </HomeLayout>
    </>
  );
};

export default CustomErrorComponent;
