import { useEffect, useState } from 'react';
import { TailfinHeroBannerComponent as Tailfin } from '@virgin-atlantic/component-lib';
import styles from './tailfin-hero-banner.component.module.less';


export default function TailfinHeroBannerComponent(props: any) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') setIsMounted(true);
  }, []);

  return isMounted && <div className={styles.tailfin}><Tailfin {...props} /></div>;
}
