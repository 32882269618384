import React, { useCallback, useEffect } from 'react';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { ClusteredHotelMarkers, HotelMapMarker } from '../clustered-hotel-markers/clustered-hotel-markers.component';
import styles from './google-map.component.module.less';

export type MapMarker = {
    key: string;
    coordinates: google.maps.LatLngLiteral;
};

interface GoogleMapPropsProps {
    mapId: string;
    markerPositions: HotelMapMarker[];
    defaultCenter: { lat: number, lng: number };
}

const GoogleMap: React.FC<GoogleMapPropsProps> = ({ mapId, markerPositions, defaultCenter }: GoogleMapPropsProps) => {

    const map = useMap(mapId);

    const repositionMap = useCallback((map: google.maps.Map) => {
        const mapEl = document.getElementById(mapId);
        if (mapEl) {
            setTimeout(() => {
                const infoWindow = mapEl.querySelector('div[class*="hotel-info-window"]');
                if (!infoWindow) return;
                const infoWindowBounds = infoWindow?.getBoundingClientRect();
                const mapBounds = mapEl.getBoundingClientRect();

                const infoWindowTop = infoWindowBounds?.top || 0;
                const mapBoundsTop = mapBounds?.top || 0;
                const topDifference = infoWindowTop - mapBoundsTop;

                if (topDifference < 0) {
                    map.panBy(0, topDifference - 20);
                }
            }, 500);
        }
    }, []);

    useEffect(() => {
        if (!map) return;

        setTimeout(() => {
            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < markerPositions.length; i++) {
                const marker = markerPositions[i];
                if (marker) {
                    bounds.extend(marker.coordinates);
                }
            }
            map.fitBounds(bounds);

            map.addListener('bounds_changed', () => {
                repositionMap(map);
            });

        }, 1000);

    }, [map, markerPositions, defaultCenter, repositionMap]);

    return (
        <Map
            className={styles['google-map']}
            id={mapId}
            mapId={mapId}
            style={{ width: '100%', height: '100%' }}
            defaultZoom={4}
            defaultCenter={defaultCenter}
            gestureHandling={'cooperative'}
            disableDefaultUI={false}
        >
            {markerPositions && <ClusteredHotelMarkers hotels={markerPositions} />}
        </Map>
    );
};

export default GoogleMap;