export const errorContent = {
    VHOLS_DEFAULT_HEADER:'Don\'t let this lost page ruin your holiday',
    VAA_DEFAULT_HEADER: 'Hmm, not sure what happened there',
    VHOLS_DEFAULT_MESSAGE: 'We can’t find the page you’re looking for but here are a few things to try instead:',
    VAA_DEFAULT_MESSAGE: 'It could be that you clicked on an old link, spelled something wrong or we\'ve just lost that page in all the excitement.',
    DEFAULT_400_MESSAGE: 'Bad request',
    VAA_ICON_PANEL_ITEMS: [
        {
            icon: 'icon-travel-plane',
            link: {
                href: 'https://www.virginatlantic.com/',
                text: 'Visit the Homepage'
            },
            text: 'Let\'s get you back on track and on your way to somewhere fabulous.',
            title: 'Let\'s try that again'
        },
        {
            icon: 'icon-travel-globe-marker',
            link: {
                href: 'https://flywith.virginatlantic.com/gb/en/destinations.html',
                text: 'Visit our Destinations page'
            },
            text: 'Browse the destinations you know and love, or discover somewhere new.',
            title: 'Explore popular destinations'
        },
        {
            icon: 'icon-contact-call',
            link: {
                href: 'https://help.virginatlantic.com/gb/en.html',
                text: 'Visit the Help Centre'
            },
            text: 'Struggling to find what you\'re looking for? Contact us. We\'re here to help.',
            title: 'Need additional help'
        }
    ],
    VHOLS_ICON_PANEL_ITEMS: [
        {
            icon: 'icon-technology-computer',
            link: {
                href: 'https://www.virginholidays.co.uk/destinations',
                text: 'View all destinations'
            },
            text: 'Have a look at our range of destinations to see what we offer.',
            title: 'Need inspiration?'
        },
        {
            icon: 'icon-action-search',
            link: {
                href: 'https://www.virginholidays.co.uk',
                text: 'Visit the home page'
            },
            text: 'It may not be on sale at the moment. Use the search bar on our homepage to find alternate hotels in the same destination.',
            title: 'Specific hotel in mind?'
        },
        {
            icon: 'icon-contact-call',
            link: {
                href: 'tel:0333 305 1489',
                text: '0333 305 1489'
            },
            text: 'Give us a call on the number below. We’re here to help.',
            title: 'Still not sure?'
        }
    ]
};