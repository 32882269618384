type Mosaic = {
  odd: number[][];
  even?: number[][];
};

type CreateMosaicClassesParams = {
  grid: Mosaic;
  prefix?: string;
  rows?: number;
  items: any[];
  rowbreak: number;
  isDesktop: boolean;
  isTablet: boolean;
};

const cache: { [key: number]: string } = {};

export function numberToAlphabet(n: number) {
  if (cache[n]) {
    return cache[n];
  }
  let result = '';
  let num = n;
  while (num > 0) {
    num--;
    result = String.fromCharCode(65 + (num % 26)) + result;
    num = Math.floor(num / 26);
  }
  cache[n] = result;
  return result;
}


export const createMosaicClasses = ({
  grid,
  prefix = 'card',
  rows = 1,
}: CreateMosaicClassesParams): string => {
  const matrix: string[] = [];

  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    const type = rowIndex % 2 === 0 ? 'even' : 'odd';
    const rowTemplate = grid[type] ?? grid['odd'];

    const rowResult = rowTemplate.map((item) => {
      return item.reduce((accu, curr) => {
        let gridIndex = curr;
        return `${accu}${prefix}${numberToAlphabet(
          rowIndex + 1
        )}${numberToAlphabet(gridIndex)} `;
      }, '');
    });

    matrix.push(`"${rowResult.join('" "')}"`);
  }
  return matrix.join(' ');
};

export const isIndexEven = (index: number, rowbreak: number) =>
  Math.floor(index / rowbreak) % 2 === 0;
