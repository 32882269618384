import { ButtonComponent, ButtonColour, ButtonSize } from '@vaa-component-lib/component.atom.button/';
import styles from './skip-links.component.module.less';
import { useRef } from 'react';
import { findNextFocusable, findPreviousFocusable } from 'src/utils/dom-utils/utils';

interface SkipLinksComponentProps {
  children?: React.ReactNode | React.ReactNode[];
  skipForwards?: {
    targetId?: string;
    label: string;
  };
  skipBackwards?: {
    targetId?: string;
    label: string;
  };
}

const SkipLinksComponent = ({ skipForwards, skipBackwards, children }: SkipLinksComponentProps) => {

  const skipLinksRef = useRef<HTMLDivElement>(null);

  const handleSkip = (e: MouseEvent | KeyboardEvent, direction: 'NEXT' | 'PREV'): void => {
    if (document) {
      const target = e?.target as HTMLAnchorElement;

      if (!target) {
        return;
      }

      const targetId = target.href.split('#')[1];
      const targetEl = document.getElementById(targetId) as HTMLElement;

      if (targetEl) {
        const { offsetTop: targetOffset } = targetEl;

        if (targetOffset < window.scrollY) {
          e.preventDefault();
          targetEl.focus();
          window.scrollTo(0, targetOffset - 1);
        }
      } else {
        if (skipLinksRef.current) {
          if (direction === 'NEXT') {
            findNextFocusable(skipLinksRef.current);
          } else {
            findPreviousFocusable(skipLinksRef.current);
          }
        }
      }
    }

    setTimeout(() => window && window.history.replaceState(null, '', ' '), 500);
  };

  const handleFocus = (e: React.FocusEvent) => {
    if (skipLinksRef.current) {
      const bounds = skipLinksRef.current.getBoundingClientRect();
      const skipLinksOffset = bounds.top;

      if (skipLinksOffset < 0) {
        e.preventDefault();
        skipLinksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <div ref={skipLinksRef} className={styles['skip-links']} role="navigation" aria-label="Skip links">
      {skipForwards && (
        <div key={`${skipForwards?.label}_forwards`} className={styles['skip-links__forwards']}>
          <ButtonComponent
            size={ButtonSize.XSmall}
            colour={ButtonColour.ContrastSecondary}
            href={`#${skipForwards?.targetId || ''}`}
            onClick={(e) => handleSkip(e, 'NEXT')}
          >
            {skipForwards?.label}
          </ButtonComponent>
        </div>
      )}
      {children}
      {skipBackwards && (
        <div
          key={`${skipBackwards?.label}_backwards`}
          className={styles['skip-links__backwards']}
          tabIndex={-1}
          onFocus={handleFocus}
          >
          <ButtonComponent
            size={ButtonSize.XSmall}
            colour={ButtonColour.ContrastSecondary}
            href={`#${skipBackwards?.targetId || ''}`}
            onClick={(e) => handleSkip(e, 'PREV')}
          >
            {skipBackwards?.label}
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};

export default SkipLinksComponent;
