// TODO: this has been copied wholesale from the Component Library project.
// Perhaps we can find a way to surface it as an export?

import { useState, useEffect } from 'react';
import tokens from '@virgin-atlantic/design-tokens/json/variables.json';

export enum BreakPointToken {
  LgMax = tokens.breakpoint.layout_lg_12_column_fluid.breakpointMax,
  LgMin = tokens.breakpoint.layout_lg_12_column_fluid.breakpointMin,
  MdMax = tokens.breakpoint.layout_md_12_columns_fluid.breakpointMax,
  MdMin = tokens.breakpoint.layout_md_12_columns_fluid.breakpointMin,
  SmMax = tokens.breakpoint.layout_sm_12_columns_fluid.breakpointMax,
  SmMin = tokens.breakpoint.layout_sm_12_columns_fluid.breakpointMin,
  XlMax = tokens.breakpoint.layout_xl_12_column_fluid.breakpointMax,
}

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
