import homeLayoutStyles from './home-layout.component.module.less';

interface HomeLayoutProps {
  children: React.ReactNode;
}

const HomeLayout = ({ children }: HomeLayoutProps) => (
  <div
    data-cy="brow-home-layout"
    className={homeLayoutStyles['homeLayoutGrid']}
  >
    {children}
  </div>
);

export default HomeLayout;
