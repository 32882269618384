import { useState, useEffect } from 'react';
import GalleryCardComponent from '../gallery-card/gallery-card.component';
import ContentCardComponent, {
  ParentComponent,
} from '../content-card/content-card.component';

import styles from './carousel.component.module.less';
import { CarouselComponent as Carousel } from '@vaa-component-lib/component.organism.carousel';
import TableCard from '../table-card/table-card.component';

function renderCardComponent(
  cardProps: any,
  cardType: 'content-card' | 'destination-card',
  itemIndex: number = 0
) {

  if (cardType === 'content-card') {
    return (
      <ContentCardComponent
        key={`Carousel--child--ccc--${itemIndex}`}
        parentComponent={ParentComponent.Carousel}
        {...cardProps}
      />
    );
  }

  if (cardType === 'destination-card') {
    return (
      <GalleryCardComponent
        key={`Carousel--child--gcc--${itemIndex}`}
        {...cardProps}
      />
    );
  }

  if (cardType === 'table-card') {
    return (
      <TableCard
        key={`Carousel--child--tcc--${itemIndex}`}
        {...cardProps}
      />
    );
  }
}

export default function CarouselComponent(props: any) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') setIsMounted(true);
  }, []);

  const propsCopy = { ...props };
  propsCopy.children = [];

  props.children.forEach((child: any, index: number) => {
    propsCopy.children.push(
      renderCardComponent(child, child.cardType, index)
    );
  });

  return (
    isMounted && (
      <div className={styles.carousel}>
        <Carousel {...propsCopy} />
      </div>
    )
  );
}
