import type { Marker } from '@googlemaps/markerclusterer';
import React, { useCallback } from 'react';
import { AdvancedMarker, MapMouseEvent } from '@vis.gl/react-google-maps';
import { HotelMapMarker } from './clustered-hotel-markers.component';

export type HotelMarkerProps = {
    hotel: HotelMapMarker;
    onClick: (hotel: HotelMapMarker) => void;
    setMarkerRef: (marker: Marker | null, key: string) => void;
};

/**
 * Wrapper Component for an AdvancedMarker for a single hotel.
 */
export const HotelMarker = (props: HotelMarkerProps) => {
    const { hotel, onClick, setMarkerRef } = props;
    const handleClick = useCallback((event: any) => {
        if (event.domEvent){
            event.domEvent.preventDefault();
        }
        onClick(hotel);
    }, [onClick, hotel]);

    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement) =>
            setMarkerRef(marker, hotel.key),
        [setMarkerRef, hotel.key]
    );

    return (
        <AdvancedMarker position={hotel.coordinates} ref={ref} onClick={(e) => handleClick(e)} />
    );
};
