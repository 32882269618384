// Utility function to determine if an element is focusable
export const isFocusable = (element: HTMLElement | null): boolean => {
    if (!element) return false;
    const focusableTags = ['A', 'BUTTON', 'INPUT', 'TEXTAREA', 'SELECT'];
    return (
        (focusableTags.includes(element.tagName) && !(element as HTMLInputElement).disabled) ||
        (element.tabIndex >= 0 && element.getAttribute('tabindex') !== '-1')
    );
};

// Function to get the previous or next focusable element
export const getFocusableElement = (
    currentElement: HTMLElement | null,
    direction: 'next' | 'previous' = 'next'
): HTMLElement | null => {
    if (!currentElement) return null;
    const allElements = Array.from(document.querySelectorAll<HTMLElement>('*'));
    const currentIndex = allElements.indexOf(currentElement);

    if (direction === 'next') {
        for (let i = currentIndex + 1; i < allElements.length; i++) {
            if (isFocusable(allElements[i]) && !currentElement.contains(allElements[i])) {
                return allElements[i];
            }
        }
    } else if (direction === 'previous') {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if (isFocusable(allElements[i]) && !currentElement.contains(allElements[i])) {
                return allElements[i];
            }
        }
    }

    return null; // Return null if no focusable element is found
};


export const findNextFocusable = (currentElement: HTMLElement | null) => {
    const nextFocusable = getFocusableElement(currentElement, 'next');
    if (nextFocusable) {
        nextFocusable.focus();
    }
};

export const findPreviousFocusable = (currentElement: HTMLElement | null) => {
    const previousFocusable = getFocusableElement(currentElement, 'previous');
    if (previousFocusable) {
        previousFocusable.focus();
    }
};