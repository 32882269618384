import React, { useRef, useEffect, useState } from 'react';
import styles from './link-banner.component.module.less';
import getIcon from 'src/components/icons';
import {
  IconNavigationChevronArrowLeftComponent,
  IconNavigationChevronArrowRightComponent,
} from '@vaa-component-lib/component.atom.icons';
import { IconSize } from '@vaa-component-lib/component.atom.icon';
import {
  ButtonComponent as Button,
  ButtonColour,
  ButtonProps,
  ButtonSize,
  ButtonType,
} from '@vaa-component-lib/component.atom.button';

export interface Link {
  label: string;
  link: string;
  icon: string;
  target?: string;
}

export interface LinksBannerProps {
  links: Link[];
  id?: string;
  showIcons?: boolean;
}

const LinksBanner = (props: LinksBannerProps) => {
  const { links, id, showIcons = false } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const updateButtonsVisibility = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftButton(scrollLeft > 50);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth - 50);
    }
  };

  const scrollIntoViewIfNeeded = (element: HTMLElement) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      if (elementRect.left < containerRect.left) {
        containerRef.current.scrollBy({
          left: elementRect.left - containerRect.left,
          behavior: 'smooth',
        });
      } else if (elementRect.right > containerRect.right) {
        containerRef.current.scrollBy({
          left: elementRect.right - containerRect.right,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    setLoaded(true);
    updateButtonsVisibility();
    window.addEventListener('resize', updateButtonsVisibility);
    return () => {
      window.removeEventListener('resize', updateButtonsVisibility);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', updateButtonsVisibility);
      return () => {
        container.removeEventListener('scroll', updateButtonsVisibility);
      };
    }
  }, []);

  return (
    <div
      className={styles['link-banner-wrapper']}
      id={id}
      data-testid="link-banner-wrapper"
    >
      {showLeftButton && (
        <span
          className={`${styles['link-banner__button']} ${styles['link-banner__button_left']}`}
          data-testid="left-scroll-button"
        >
          <Button
            iconChild={
              <IconNavigationChevronArrowLeftComponent
                size={IconSize.Xxs as unknown as any}
              />
            }
            onClick={scrollLeft}
            type={ButtonType.Icon}
            size={ButtonSize.Small}
            colour={ButtonColour.Inverse}
          ></Button>
        </span>
      )}
      <div
        className={`${styles['link-banner']} ${loaded ? 'loaded' : ''}`}
        ref={containerRef}
        role="navigation"
        aria-labelledby={`${id}-title`}
        data-testid="link-banner"
      >
        {links.map((link, index) => {
          const { link: href, target, label, icon } = link;

          const buttonOptions: any = {
            type: ButtonType.Button,
            'aria-label': label,
            'aria-role': 'button',
            text: label,
            'data-testid': `link-item-${index}`,
            href,
            target: target === 'new' ? '_blank' : '_self',
            colour: ButtonColour.ContrastSecondary,
            style: { animationDelay: `${index * 0.1}s` },
            className: `${styles['link-banner__link-item']} ${
              loaded ? styles['loaded'] : ''
            }`,
            onFocus: (event: React.FocusEvent<HTMLButtonElement>) => {
              scrollIntoViewIfNeeded(event.currentTarget);
            },
          };

          if (icon && showIcons) {
            const Icon = getIcon(icon);
            buttonOptions.iconChild = <Icon />;
          }

          return (
            <Button
              key={index}
              {...(buttonOptions as unknown as ButtonProps)}
            ></Button>
          );
        })}
      </div>
      {showRightButton && (
        <span
          className={`${styles['link-banner__button']} ${styles['link-banner__button_right']}`}
          data-testid="right-scroll-button"
        >
          <Button
            iconChild={
              <IconNavigationChevronArrowRightComponent
                size={IconSize.Xxs as unknown as any}
              />
            }
            onClick={scrollRight}
            type={ButtonType.Icon}
            size={ButtonSize.Small}
            colour={ButtonColour.Inverse}
          ></Button>
        </span>
      )}
    </div>
  );
};

export default LinksBanner;
