import {
  Tab,
  TabsComponent as Tabs,
  TabsProps,
  IconPosition,
} from '@vaa-component-lib/component.molecule.tabs';
import getIcon from '../icons';
import { renderComponent } from 'src/utils/component-utils/utils';
import styles from './tabs-component.module.less';

interface TabsComponentProps {
  id: string;
  items: any[];
  activeItem: string;
  itemsOrder: string[];
  accessibilityLabel: string;
  lozenge?: boolean;
}

const handleTabItemProps = (
  id: number,
  tab: any,
  activeItem: string
): Tab | null => {
  const tabItem = tab && tab.tabItem;

  if (!tabItem) {
    return null;
  }
  const Icon = tabItem.iconSvg && getIcon(tabItem.iconSvg);

  return {
    id: id,
    label: tabItem['panelTitle'],
    content: renderComponent('tab-item', id, tabItem),
    disabled: tabItem.isDisabled || false,
    iconChild: Icon && <Icon />,
    iconPosition:
      tabItem.iconPosition === 'right' ? IconPosition.Right : IconPosition.Left,
    selected: activeItem === tab.name,
  };
};

export default function TabsComponent(tabsProps: TabsComponentProps) {
  if (!tabsProps.items) {
    return null;
  }

  const orderedItems = tabsProps.itemsOrder
    .map((item: string) =>
      tabsProps.items.find((tab: any) => tab.name === item)
    )
    .filter((item: any) => item !== undefined);

  let activeItem = tabsProps.activeItem;
  const activeItemIsDisabled = orderedItems.find(
    (item: any) => item.name === activeItem
  )?.tabItem.isDisabled;

  if (activeItemIsDisabled) {
    const firstEnabledItem = orderedItems.find(
      (item: any) => !item.tabItem.isDisabled
    );
    activeItem = firstEnabledItem?.name || '';
  }

  const tabsPropsUpdated: TabsProps = {
    lozenge: tabsProps.lozenge,
    tabId: tabsProps.id,
    showIcons: true,
    accessibilityLabel: tabsProps.accessibilityLabel,
    tabs: orderedItems
      .map((tab: any, tabIndex: number) =>
        handleTabItemProps(tabIndex, tab, activeItem)
      )
      .filter((tab: Tab | null) => tab !== null) as Tab[],
  };

  return (
    <div className={styles.tabs}>
      <Tabs {...tabsPropsUpdated} />
    </div>
  );
}
