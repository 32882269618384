import { AccordionComponent as Accordion } from '@vaa-component-lib/component.organism.accordion';
import { AccordionItemProps } from '@vaa-component-lib/component.molecule.accordion-item';
import SVGRenderer from '../svg-renderer/svg-renderer.component';
import TextLockupComponent from '../text-lockup/text-lockup.component';
import ContentCardComponent from '../content-card/content-card.component';
import GalleryCardComponent from '../gallery-card/gallery-card.component';
import TableCard from '../table-card/table-card.component';
import { VAAContext } from 'pages/_app';
import { useContext } from 'react';

export default function AccordionComponent(accordionProps: any) {
  const { accordionAnchor } = useContext(VAAContext);

  if (!accordionProps.items) {
    return null;
  }

  // Mutate accordion item content props into actual components
  const accordionItems: AccordionItemProps[] = accordionProps.items.map(
    (accordionItem: any, accordionItemIndex: number) => {
      const {
        expandedLabel,
        collapsedLabel,
        index,
        id,
        icon: iconText,
        content,
      } = accordionItem;

      let { isOpen } = accordionItem;

      if (accordionAnchor === id) {
        isOpen = true;
      }

      if (!Array.isArray(content) && content.length === 0) {
        console.error('Accordion Item has no content');
        return;
      }

      let icon = null;
      if (iconText && iconText.startsWith('<svg')) {
        icon = <SVGRenderer svgString={iconText} />;
      }

      return {
        id,
        index,
        expandedLabel,
        collapsedLabel,
        enableOnVisible: true, // true by default
        isOpen,
        icon,
        content: [
          content.map((contentItem: any, contentItemIndex: any) => {
            if (!contentItem['sling:resourceType']) {
              return null;
            }
            const contentItemType = contentItem['sling:resourceType']
              .split('/')
              .pop();

            if (contentItemType === 'text-lockup-component') {
              const { children } = contentItem;
              // delete contentItem.children; // because we are passing it as a prop
              return (
                <TextLockupComponent
                  key={`tlc--${accordionItemIndex}--${contentItemIndex}`}
                  noPadding={true}
                  {...contentItem}
                >
                  {children}
                </TextLockupComponent>
              );
            }

            if (contentItemType === 'content-card') {
              return (
                <ContentCardComponent
                  key={`cc--${accordionItemIndex}--${contentItemIndex}`}
                  {...contentItem}
                />
              );
            }

            if (contentItemType === 'destination-card') {
              return (
                <GalleryCardComponent
                  key={`gcc--${accordionItemIndex}--${contentItemIndex}`}
                  {...contentItem}
                />
              );
            }

            if (contentItemType === 'table-card') {
              return (
                <TableCard
                  key={`tcc--${accordionItemIndex}--${contentItemIndex}`}
                  {...contentItem}
                />
              );
            }
          }),
        ],
      };
    }
  );

  return (
    <Accordion heading={accordionProps.heading} items={accordionItems as any} />
  );
}
