import styles from './home-page-section.module.less';

interface HomePageSectionProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  isBottomMost?: boolean;
  sectionId?: string;
  dataCy?: string;
}

const Section = ({
  children,
  fullWidth = false,
  isBottomMost = false,
  sectionId = '',
  dataCy = '',
}: HomePageSectionProps) => {
  const sectionProps: Record<string, string> = {};
  const parentDivProps: Record<string, string> = {};
  sectionId ? (sectionProps['id'] = sectionId) : null;
  dataCy ? (parentDivProps['data-cy'] = dataCy) : null;

  return (
    <section {...sectionProps}>
      <div
        {...parentDivProps}
        className={`${!isBottomMost ? styles['vertical-padding-bottom'] : ''} ${
          !fullWidth ? styles['padded-width'] : ''
        } ${styles['grid-layout']}`}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
