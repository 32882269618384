/*
  Use this hook to access any common props from BaseComponent.
  The returned commonProps object can then be spread onto the component using this { ...  The returned commonProps object can then be spread onto the component using this { ...commonProps }
 }
*/
export interface BaseComponent {
	id?: string
	ariaLabel?: string
	ariaControls?: string
	tabIndex?: '-1' | '0' | '1'
	testId?: string
	testAttribute?: string
	testAttributeValue?: string
	/**
	 * @deprecated use testId instead
	 */
	dataCy?: string
}

export default function useCommonProps(props: BaseComponent) {
	const {
		testId,
		ariaLabel,
		ariaControls,
		tabIndex,
		id,
		dataCy,
		testAttribute,
		testAttributeValue
	} = props;
	const testAttrPrefix = /^data-/.test(testAttribute || '') ? '' : 'data-';

	return {
		...(testId && { 'data-testid': testId }),
		...(id && { id: id }),
		...(ariaLabel && { 'aria-label': ariaLabel }),
		...(ariaControls && { 'aria-controls': ariaControls }),
		...(tabIndex && { tabindex: tabIndex }),
		...(dataCy && { 'data-cy': dataCy }),
		...(testAttribute && { [`${testAttrPrefix}${testAttribute}`]: testAttributeValue || '' })
	};
}
