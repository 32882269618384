import { ReactNode } from 'react';
import styles from './grid-item.component.module.less';
import classnames from 'classnames';


interface GridItemComponentProps {
  children: ReactNode | ReactNode[];
  span: number;
  offset?: number;
  isFullWidth?: boolean;
  marginBottom?: boolean;
}

const GridItem = ({ children, span, offset=0, isFullWidth, marginBottom }: GridItemComponentProps) => {
  if (!children) {
    return null;
  }

  return <div className={classnames(styles.gridItem, {
    [styles[`span-${span}`]]: true,
    [styles[`offset-${offset}`]]: true,
    [styles['full-width']]: !isFullWidth,
    [styles['margin-bottom']]: marginBottom,
    })}>{children}</div>;
};

export default GridItem;
