import classnames from 'classnames';
import styles from './heading-component.component.module.less';
import {
  TypographyComponent,
  TypographyVariant,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';

interface HeadComponentProps {
  heading: string;
  align: 'left' | 'center' | 'right';
  variant: TypographyVariant;
  size: TypographySize;
  weight: TypographyWeight;
  maxWidth?: number | null;
}

const HeadingComponent = ({
  heading,
  align = 'left',
  variant = TypographyVariant.Heading,
  size = TypographySize.Lrg,
  weight = TypographyWeight.Regular,
  maxWidth = null,
}: HeadComponentProps) => {
  const mystyle = {
    maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
  };

  return (
    <div
      className={classnames(styles['heading-component'], {
        [styles['align_center']]: align === 'center',
        [styles['align_right']]: align === 'right',
      })}
      style={mystyle}
    >
      <TypographyComponent variant={variant} size={size} weight={weight}>
        <span title={heading}>{heading}</span>
      </TypographyComponent>
    </div>
  );
};

export default HeadingComponent;
